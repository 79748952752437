import request from '@/utils/request'

// 查询套餐项目列表
export function findPackagesItems(params) {
  return request({
    url: `/packages_items`,
    method: 'get',
    params: params
  })
}

// 集团租户管理界面查询套餐项目列表
export function findTenantPackagesItems(params) {
  return request({
    url: `/packages_items/tenant`,
    method: 'get',
    params: params
  })
}

// 查询排序列表
export function findSortPackagesItems(params) {
  return request({
    url: `/packages_items/sort`,
    method: 'get',
    params: params
  })
}

// 查询套餐导入项目列表
export function findPackagesImportItems(params) {
  return request({
    url: `/packages_items/import_items`,
    method: 'get',
    params: params
  })
}

// 导出套餐项目
export function importItems(data) {
  return request({
    url: `/packages_items/import`,
    method: 'post',
    data
  })
}

// 删除套餐项目
export function deletePackagesItems(id) {
  return request({
    url: `/packages_items/${id}`,
    method: 'delete'
  })
}
