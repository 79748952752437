<template>
  <div>
    <search-package-item @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >

      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="deletePackagesItems(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增项目模态框 -->
    <new-package-item
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :package-id="parseInt($route.params.id)"
      @completed="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <sort
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      table-name="packages_items"
      name-title="项目名称"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchPackageItem from '@/views/packages_items/Search'
import PreviewImage from '@/components/PreviewImage'
import { deletePackagesItems, findPackagesItems, findSortPackagesItems } from '@/api/packages_item'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'PackagesItemList',
  components: {
    Pagination,
    PreviewImage,
    SearchPackageItem,
    Sort: () => import('@/components/Sort'),
    NewPackageItem: () => import('@/views/packages_items/New')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      previewVisible: false,
      previewImages: [],
      isShowSortModal: false,
      sortData: [],
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '项目代码',
          dataIndex: 'code',
          width: 100
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          dataIndex: 'price',
          customRender: formatCurrency
        },
        {
          title: '图片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '项目介绍',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showSortModal() {
      findSortPackagesItems({ package_id: this.$route.params.id }).then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      this.$refs.searchForm.clearForm()
      this.loading = true
      findPackagesItems({ package_id: this.$route.params.id }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      const queryData = Object.assign({}, this.query, { package_id: this.$route.params.id })
      findPackagesItems(queryData).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    deletePackagesItems(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？',
        content: '删除后无法恢复',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deletePackagesItems(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
