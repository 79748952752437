<template>
  <div>
    <div class="custom-card-box">
      <a-row :gutter="12">
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions-max-7 custom-descriptions">
            <a-descriptions-item label="套餐名称">{{ packageInfoData.name }}</a-descriptions-item>
            <a-descriptions-item label="所属殡仪馆">{{ packageInfoData.funeral_home_names }}</a-descriptions-item>
            <a-descriptions-item label="关联厅房">{{ packageInfoData.relation_hall_names }}</a-descriptions-item>
            <a-descriptions-item label="套餐价格(元)">{{ packageInfoData.price | formatCurrency }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions custom-descriptions-max-7">
            <a-descriptions-item label="状态">{{ packageInfoData.effective? '有效':'无效' }}</a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ packageInfoData.created_at }}</a-descriptions-item>
            <a-descriptions-item label="套餐介绍">{{ packageInfoData.remark }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions">
            <a-descriptions-item label="项目代码">{{ packageInfoData.code }}</a-descriptions-item>
            <a-descriptions-item label="拼音码">{{ packageInfoData.code }}</a-descriptions-item>
            <a-descriptions-item label="图片">
              <template v-if="packageInfoData.images === null" />
              <template>
                <img class="set-img" v-for="(item, index) in packageInfoData.images" :key="index" :src="item.url" @click="showImg(packageInfoData)">
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </div>

    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <div class="custom-card-box" style="margin-top: 20px;">
      <package-item-list />
    </div>

  </div>
</template>

<script>
import { findPackagesInfo } from '@/api/package_management'
import PackageItemList from '@/views/packages_items/index'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'ShowPackageInfo',
  components: {
    PackageItemList,
    PreviewImage
  },
  created() {
    this.fetchData()
  },
  data() {
    return {
      packageInfoData: {},
      pagination: {
        total_count: 0
      },
      recordId: this.$route.params.id,
      previewVisible: false,
      previewImages: []
    }
  },
  methods: {
    fetchData() {
      findPackagesInfo(this.$route.params.id).then((res) => {
        if (res.code === 0) {
          this.packageInfoData = res.data
          this.pagination = res.pagination
        }
      })
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    }
  }
}
</script>
<style lang="less" scoped>
.set-img {
  width: 40%;
}
</style>

