import request from '@/utils/request'

// 查询项目列表
export function findItems(params) {
  return request({
    url: `/items`,
    method: 'get',
    params: params
  })
}

// 查询排序列表
export function findSortItems(params) {
  return request({
    url: `/items/sort`,
    method: 'get',
    params: params
  })
}

// 创建项目
export function createItem(data) {
  return request({
    url: `/items`,
    method: 'post',
    data
  })
}

// 更新项目
export function updateItem(itemId, data) {
  return request({
    url: `/items/${itemId}`,
    method: 'put',
    data
  })
}

// 删除项目
export function deleteItem(itemId) {
  return request({
    url: `/items/${itemId}`,
    method: 'delete'
  })
}

// 获取项目类型
export function getItemTypeOptions() {
  return request({
    url: `/items/type/options`,
    method: 'get'
  })
}

// 更新项目有效性
export function updateItemEffective(id, data) {
  return request({
    url: `/items/${id}/effective`,
    method: 'put',
    data
  })
}

// 检验项目是否有效
export function checkItemValid(data) {
  return request({
    url: `/items/check`,
    method: 'get',
    params: data
  })
}

